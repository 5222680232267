import React, { useState, useEffect } from "react";
import { Bnb } from 'react-web3-icons';

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import config from "../../utils/global";
import { error } from "../../utils/toast";
import { replaceWalletAddress, formatWalletAddress, formatNumberWithCommas, formatVietnamTime, formatVndDate, isMobile } from "../../utils/tools";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const History = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [seasonID, setSeasonID] = useGlobalState(
    "seasonID"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [historyInfo, setHistoryInfo] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [attrList] = useState([
    { id: 0, text: "Giao dịch", val: 'transaction' },
    { id: 1, text: "Đơn đặt hàng", val: 'order' }
  ]);
  const [attrChecked, setAttrChecked] = useState('transaction');
  const [seasonList] = useState([
    { id: 0, text: "Vượt ngàn chông gai", val: '1' },
    // { id: 1, text: "Chị Đẹp Đạp Gió Rẽ Sóng 2024", val: '2' }
  ]);
  const elementType = {
    1: "Cơ bản",
    2: "Lửa"
  };
  const nftLevs = {
    0: "Fire",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Platinum",
    5: "Diamond"
  };
  let season = seasonID;

  useEffect(() => {
    async function fetchData() {
      await fetchHistory();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchHistory = async () => {
    const rep = await get("yeah1Apps/getTradeHistory", {
      seasonID: season,
      pageNo: 1,
      pageSize: 500,
    });
    if (rep.code === 0) {
      setHistoryList(rep.data.historyRecords);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const fetchOrder = async () => {
    const rep = await get("yeah1Payment/getOrderHistory", {
      seasonID: season,
      pageNo: 1,
      pageSize: 500,
    });
    if (rep.code === 0) {
      setOrderList(rep.data.orderList);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleMyNftDetails = (id) => {
    const filteredRows = historyList.filter((rows) => rows.id === id);
    setHistoryInfo(filteredRows[0]);
    openModal();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleTag = (chk) => {
    // console.log('handleTag=>chk=>', chk);
    if (chk === 'order') {
      fetchOrder();
    } else {
      fetchHistory();
    }
    setAttrChecked(chk);
    setLoaderState(true);
  };

  const handleScan = (_contract_address, event) => {
    event.stopPropagation();
    if (isMobile()) {
      window.location.href = `${config.network.exploreUrl}/${replaceWalletAddress(_contract_address)}/60`;
    } else {
      window.open(`${config.network.exploreUrl}/${replaceWalletAddress(_contract_address)}/60`);
    }
  };

  const handleChange = async (event) => {
    setSeasonID(event.target.value);
    season = event.target.value;
    if (attrChecked === 'order') {
      await fetchOrder();
    } else {
      await fetchHistory();
    }
  };

  const runderInfo = (_items) => {
    switch (_items.tradeType) {
      case 0:
        return (
          <div className="info">
            <div className="title">{_items.seasonName}</div>
            <div className="operation">
              <div className="addr">
                to {_items.targetInfo.id}
                {_items.targetInfo.nickName && `(${_items.targetInfo.nickName})`}
              </div>
              <div className="state">
                <span className="green">Đã gửi</span>
              </div>
            </div>
            <div className="time">{formatVndDate(_items.tradeTime)}</div>
          </div>
        );
      case 1:
        return (
          <div className="info">
            <div className="title">{_items.seasonName}</div>
            <div className="operation">
              <div className="addr">
                from {_items.targetInfo.id}
                {_items.targetInfo.nickName && `(${_items.targetInfo.nickName})`}
              </div>
              <div className="state">
                <span className="green">Đã nhận</span>
              </div>
            </div>
            <div className="time">{formatVndDate(_items.tradeTime)}</div>
          </div>
        );
      default:
        return (
          <div className="info">
            <div className="title">{_items.seasonName}</div>
            <div className="operation">
              <div className="addr">to {formatWalletAddress(_items.targetInfo)}</div>
              <div className="state">
                <span className="red">Chi tiết</span>
              </div>
            </div>
            {_items.targetInfo && (
              <div className="contract">
                <Bnb size={20} />
                <span className="chain" onClick={(e) => handleScan(_items.targetInfo, e)}>Xem trên BNB CHAIN</span>
              </div>
            )}
            <div className="time">{formatVndDate(_items.tradeTime)}</div>
          </div>
        );
    }
  };

  const renderOrderStatus = (_status) => {
    switch (_status) {
      case 1:
        return (
          <span className="red">Thanh toán thất bại</span>
        );
      case 2:
        return (
          <span className="green">Thanh toán thành công</span>
        );
      case 3:
        return (
          <span className="green">Thành công một phần</span>
        );
      case 4:
        return (
          <span className="red">Giao dịch bị hủy</span>
        );
      default:
        return (
          <span className="green">Đang chờ thanh toán</span>
        );
    }
  };

  const renderOrderType = (_orderType) => {
    switch (_orderType) {
      case 0:
        return "Mua";
      case 1:
        return "Chuyển";
      case 2:
        return "Rút";
      default:
        return "Khác";
    }
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Lịch sử giao dịch`}
        description={`Yvote - Funfusion Lịch sử giao dịch`}
        keywords={`Yvote,Funfusion,NFT,Web3,Lịch sử giao dịch`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="history">
        <select
          className="select"
          value={season}
          onChange={handleChange}
        >
          {seasonList.map((sea, index) => (
            <option key={`season_${index}`} value={sea.val}>{sea.text}</option>
          ))}
        </select>
        <div className="tags-bar">
          <div className="tags-list">
            {attrList.map((tag, index) => (
              <div
                key={`tag_${index}`}
                className={attrChecked === tag.val ? "chk" : "tag"}
                onClick={() => handleTag(tag.val)}
              >
                {tag.text}
              </div>
            ))}
          </div>
        </div>
        {attrChecked === 'order' ? (
          <>
            {orderList.length > 0 ? (
              <ul className="list">
                {orderList.map((item) => (
                  <li key={item.order_id}>
                    <div className="cover">
                      {item.order_type === 0 ? (
                        <ImageLoader css={"pic"} src={item.show_image} alt={`Cấp độ ${item.level} package`} />
                      ) : (
                        <ImageLoader
                          src={item.show_image}
                          alt={item.show_name}
                        />
                      )}
                    </div>
                    <div className="info">
                      {item.order_type === 0 ? (
                        <div className="title">
                          {nftLevs[item.level]}&nbsp;{`(${renderOrderType(item.order_type)})`}
                        </div>
                      ) : (
                        <div className="title">
                          {item.show_name}&nbsp;{`(${renderOrderType(item.order_type)})`}
                        </div>
                      )}
                      <div className="operation">
                        <div className="addr">
                          {formatNumberWithCommas(item.total_price || 0)}
                        </div>
                        <div className="state">
                          {renderOrderStatus(item.order_status)}
                        </div>
                      </div>
                      <div className="time">{formatVietnamTime(item.create_time || '')}</div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="nodata">
                Hiện tại không có ghi nhận gửi và rút quà tặng số.
              </div>
            )}
          </>
        ) : (
          <>
            {historyList.length > 0 ? (
              <ul className="list">
                {historyList.map((item) => (
                  <li key={item.id} onClick={() => handleMyNftDetails(item.id)}>
                    <div className="cover">
                      <ImageLoader
                        src={item.nftInfo.show_image}
                        alt={item.nftInfo.show_name}
                      />
                    </div>
                    {runderInfo(item)}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="nodata">
                Hiện tại không có ghi nhận gửi và rút quà tặng số.
              </div>
            )}
          </>
        )}

      </div>
      <Footer />
      <Modal styleCss={"default"} isOpen={modalOpen} onClose={closeModal}>
        {historyInfo.nftInfo ? (
          <div className="history-nft-details">
            <ImageLoader
              css="pic"
              src={historyInfo.nftInfo.show_image || ""}
              alt={historyInfo.nftInfo.show_name || ""}
            />
            <div className="rows">{historyInfo.nftInfo.show_name || ""}</div>
            <div className="rows">Cấp độ {historyInfo.nftInfo.nftLv || 1}</div>
            <div className="rows">
              {elementType[historyInfo.nftInfo.nftType || 1]}
            </div>
            {historyInfo.tradeType === 2 && (
              <div className="rows">
                <Bnb size={20} />
                <span className="chain" onClick={(e) => handleScan(historyInfo.targetInfo, e)}>Xem trên BNB CHAIN</span>
              </div>
            )}
          </div>
        ) : null}
      </Modal>
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default History;
